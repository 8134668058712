<template>
  <div class="min-h-screen bg-gray-100">
    <nav class="bg-white shadow-sm">
      <div class="mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <img class="block lg:hidden h-8 w-auto" src="@/assets/images/compos-logo.svg" alt="Compos logo" />
              <img class="hidden lg:block h-8 w-auto" src="@/assets/images/compos-logo.svg" alt="Compos logo" />
            </div>
            <div class="hidden sm:ml-6 sm:flex">
              <router-link
                :to="{ name: 'user-start' }"
                exact-active-class="border-indigo-500"
                href="#"
                class="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out"
                >{{ $t("user.menu.home") }}</router-link
              >
              <router-link
                :to="{ name: 'user-patients' }"
                exact-active-class="border-indigo-500"
                href="#"
                class="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
                >{{ $t("user.menu.patients") }}</router-link
              >
              <!-- <router-link
                :to="{ name: 'user-reports' }"
                exact-active-class="border-indigo-500"
                href="#"
                class="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
              >{{ $t('user.menu.reports') }}</router-link>-->
              <!-- <router-link
                :to="{ name: 'user-monitoring' }"
                exact-active-class="border-indigo-500"
                href="#"
                class="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
              >{{ $t('user.menu.monitoring') }}</router-link>-->
              <!-- <a
                href="#"
                class="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
              ></a>-->
              <router-link
                :to="{ name: 'user-user-admin' }"
                exact-active-class="border-indigo-500"
                href="#"
                class="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
                >{{ $t("user.menu.admin.users") }}</router-link
              >
              <router-link
                :to="{ name: 'user-app-admin' }"
                exact-active-class="border-indigo-500"
                href="#"
                class="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
                >{{ $t("user.menu.admin.app") }}</router-link
              >
              <router-link
                :to="{ name: 'user-modules-admin' }"
                exact-active-class="border-indigo-500"
                href="#"
                class="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
                >{{ $t("user.menu.admin.modules") }}</router-link
              >
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <cm-button class="border-none relative" aria-label="Notifications">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>

              <!--
                <!- use as notification for user actions? ->
                <div class="absolute left-2 top-1">
                <span class="flex h-3 w-3">
                  <span
                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"
                  ></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                </span>
              </div>
              -->
            </cm-button>

            <!-- Profile dropdown -->
            <div class="ml-3 relative">
              <div>
                <cm-button
                  @click="dropdown = !dropdown"
                  class="flex text-sm border-2 border-transparent"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                >
                  {{ info.name }}, {{ currentUnitName }}
                </cm-button>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="ransform opacity-100 scale-100  "
                leave-active-class="transition ease-in duration-75"
                leave-class="ransform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <div
                  v-if="dropdown"
                  v-on-click-outside="() => (dropdown = false)"
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50"
                >
                  <div class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
                    <router-link
                      v-if="unitList && unitList.length > 1"
                      :to="{ name: 'login-select-unit', params: { switch: true } }"
                      class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      >{{ $t("user.menu.switchunit") }}</router-link
                    >
                    <a
                      @click="edit"
                      href="#"
                      class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      >{{ $t("user.menu.my_account") }}</a
                    >
                    <a
                      @click="logout"
                      href="#"
                      class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      >{{ $t("user.menu.logout") }}</a
                    >
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <button
              @click="menu = !menu"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <!-- Menu open: "hidden", Menu closed: "block" -->
              <svg
                class="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                :class="{ hidden: menu, block: !menu }"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <!-- Menu open: "block", Menu closed: "hidden" -->
              <svg
                class="h-6 w-6"
                :class="{ hidden: !menu, block: menu }"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!--
      Mobile menu, toggle classes based on menu state.

      Open: "block", closed: "hidden"
      -->
      <div class="sm:hidden" :class="{ hidden: !menu, block: menu }">
        <div class="pt-2 pb-3">
          <router-link
            :to="{ name: 'user-start' }"
            exact-active-class="border-indigo-500"
            href="#"
            class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
            >{{ $t("user.menu.home") }}</router-link
          >
          <router-link
            :to="{ name: 'user-patients' }"
            exact-active-class="border-indigo-500"
            href="#"
            class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
            >{{ $t("user.menu.patients") }}</router-link
          >
          <router-link
            :to="{ name: 'user-user-admin' }"
            exact-active-class="border-indigo-500"
            href="#"
            class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
            >{{ $t("user.menu.admin.users") }}</router-link
          >
        </div>
        <div class="pt-4 pb-3 border-t border-gray-200">
          <div class="flex items-center px-4">
            <div class="flex-shrink-0">...</div>
            <div class="ml-3">
              <div class="text-base font-medium leading-6 text-gray-800">{{ info.name }}</div>
              <div class="text-sm font-medium leading-5 text-gray-500">{{ info.email }}</div>
              <div class="text-sm font-medium leading-5 text-gray-500">, {{ currentUnitName }}</div>
            </div>
          </div>
          <div class="mt-3" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <router-link
              v-if="unitList && unitList.length > 1"
              :to="{ name: 'login-select-unit', params: { switch: true } }"
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >{{ $t("user.menu.switchunit") }}</router-link
            >
            <a
              @click="edit"
              href="#"
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >{{ $t("user.menu.my_account") }}</a
            >
            <a
              @click="logout"
              href="#"
              class="mt-1 block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
              role="menuitem"
              >{{ $t("user.menu.logout") }}</a
            >
          </div>
        </div>
      </div>
    </nav>

    <div class="p-0">
      <!-- <header>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold leading-tight text-gray-900">Dashboard</h1>
        </div>
      </header>-->
      <main>
        <router-view />
      </main>
    </div>
    <modal
      name="my-account-form"
      :click-to-close="false"
      :scrollable="true"
      :resizable="true"
      :draggable="false"
      :adaptive="false"
      :reset="true"
      :pivotY="0"
      :height="'auto'"
      :width="'50%'"
      classes="w-full bg-white rounded shadow-md transform transition-all object-contain overflow-visible"
    >
      <user-edit :user="info" :selfedit="true" @cancel="cancelEdit" />
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserEdit from "@/components/admin/UserEdit";

export default {
  name: "User",
  components: {
    UserEdit,
  },
  data() {
    return {
      dropdown: false,
      menu: false,
    };
  },
  created() {
    this.$store.dispatch("user/deactivatePatient");
  },
  computed: {
    ...mapState("user", {
      info: state => state.info,
    }),
    ...mapState("userExtra", {
      currentUnitName: state => state.currentUnitName,
      unitList: state => state.unitList,
    }),
    ...mapState("admin", {
      userlist: state => state.userlist,
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "login" }).catch(error => {
          console.log(error);
        });
      });
    },
    edit() {
      this.$modal.show("my-account-form");
    },
    cancelEdit() {
      this.$modal.hide("my-account-form");
    },
  },
};
</script>
